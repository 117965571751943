import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: 'home' */ "@/pages/home"),
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () =>
      import(/* webpackChunkName: 'schedule' */ "@/pages/schedule"),
  },
  {
    path: "/conference",
    name: "conference",
    component: () =>
      import(/* webpackChunkName: 'conference' */ "@/pages/conference"),
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () =>
      import(/* webpackChunkName: 'introduce' */ "@/pages/introduce"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: 'contact' */ "@/pages/contact"),
  },
  {
    path: "/previous",
    name: "previous",
    component: () =>
      import(/* webpackChunkName: 'previous' */ "@/pages/previous"),
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: 'support' */ "@/pages/support"),
  },
  {
    path: "/material",
    name: "material",
    component: () =>
      import(/* webpackChunkName: 'material' */ "@/pages/material"),
  },
  {
    path: "/accommodation",
    name: "accommodation",
    component: () =>
      import(/* webpackChunkName: 'accommodation' */ "@/pages/accommodation"),
  },
  {
    path: "/exhibition",
    name: "exhibition",
    component: () =>
      import(/* webpackChunkName: 'exhibition' */ "@/pages/exhibition"),
  },
  {
    path: "/iframe",
    name: "iframe",
    component: () => import(/* webpackChunkName: 'iframe' */ "@/pages/iframe"),
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === "development" ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

/* 解决vue页面之间跳转，页面不是在顶部的问题,一定要调用 next 方法，否则钩子就不会被销毁
 *to 即将进入的路由 from 即将离开的路由 next 放行
 */
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
    //  移动端
    if (to.name === "iframe") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    //  PC端
    if (to.name !== "iframe") {
      if (self.frameElement && self.frameElement.tagName == "IFRAME") {
        next();
      } else {
        next({ path: "/iframe" });
      }
    } else {
      next();
    }
  }
});

export default router;
